import PageContainer from '../Components/Layout/PageContainer';

import { Box, Text } from '@chakra-ui/react';
import { BsDatabase } from 'react-icons/bs';
import { AiOutlineWarning, AiOutlinePlus } from 'react-icons/ai';
import { BiCommentDetail } from 'react-icons/bi';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import logo from '../Images/logo.png';
import { RiAdminLine } from 'react-icons/ri';
import DashboardCard from '../Components/Dashboard/DashboardCard';
import { useContext, useMemo } from 'react';
import UserContext from '../context/user/UserContext';
import { isUserRiskManager } from '../context/user/utils';

const Dashboard = () => {
	const { user } = useContext(UserContext);
	const isRiskManager = useMemo(() => user && isUserRiskManager(user), [user]);

	const cards = useMemo(
		() => [
			{ title: 'Add Risk', url: '/risk', icon: AiOutlinePlus },
			{ title: 'Assess/Edit Risk', url: '/risks', icon: BsDatabase },
			// {
			// 	title: 'My Controls',
			// 	// TODO: Needs to be implemented
			// 	url: '',
			// 	icon: MyControls_Svg,
			// },
			{ title: 'My Actions', url: '/actions', icon: AiOutlineWarning },
			{ title: 'My Reviews', url: '/reviews', icon: BiCommentDetail },
			{ title: 'Reporting', url: '/reporting', icon: HiOutlineDocumentReport },
			{
				title: 'Admin Settings',
				url: '/admin/settings',
				icon: RiAdminLine,
				isHidden: !isRiskManager,
			},
		],
		[isRiskManager]
	);
	return (
		<PageContainer hideLogo>
			<Box ml={8} as='img' src={logo} width='25%' maxWidth={200} />

			<Text color='primary' fontSize={52} textAlign='center' lineHeight='60px'>
				Enterprise{' '}
				<b>
					Risk <br /> Management
				</b>{' '}
				System
			</Text>
			<Box
				maxWidth={900}
				mx='auto'
				mt={12}
				gap={8}
				width='100%'
				display='flex'
				alignItems='center'
				justifyContent='center'
				flexWrap='wrap'>
				{cards.map((card, index) => (
					<DashboardCard card={card} key={index} />
				))}
			</Box>
		</PageContainer>
	);
};

export default Dashboard;
