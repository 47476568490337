import { useDispatch, useSelector } from 'react-redux';
import RisksTableHead from '../../Risks/TableHead';
import { useEffect, useState } from 'react';
import RisksTableRow from '../../Risks/TableRow';
import LoadingSpinner from '../../common/LoadingSpinner';
import moment from 'moment';
import { fetchRisks } from '../../../redux/slices/risks';
import { Heading, Table, TableContainer, Tbody } from '@chakra-ui/react';

const ReviewRisksList = (props) => {
	const risks = useSelector((state) => state.risks.risks);
	const [isLoading, setIsLoading] = useState(false);
	const { selectedReview } = props;
	const dispatch = useDispatch();

	const listRisks = async () => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);
		const quarter = parseInt(selectedReview.period?.replace('Q', '') || '1');
		let filters = {
			startDate: moment().quarter(quarter).startOf('quarter').toISOString(),
			endDate: moment().quarter(quarter).endOf('quarter').toISOString(),
		};

		if (selectedReview.type === 'department') {
			filters.department = selectedReview.department;
			filters.business = selectedReview.business;
		} else if (selectedReview.type === 'business') {
			filters.business = selectedReview.business;
		}

		await dispatch(fetchRisks(filters));

		setIsLoading(false);
	};

	useEffect(() => {
		listRisks();
	}, [selectedReview]);

	return (
		<div className='p-2' style={{ overflowX: 'auto', width: '100%' }}>
			<Heading fontSize='xl' py={0}>Review Risks</Heading>

			{isLoading && <LoadingSpinner />}

			{!isLoading && !Boolean(risks.length) && <div style={{ marginTop: 16 }}>No risks found</div>}

			{Boolean(risks.length) && !isLoading && (
				<TableContainer>
					<Table colorScheme='gray' variant='striped'>
						<RisksTableHead />
						<Tbody>
							{risks.map((risk, i) => (
								<RisksTableRow risk={risk} key={i} i={i} />
							))}
						</Tbody>
					</Table>
				</TableContainer>
			)}
		</div>
	);
};

export default ReviewRisksList;
