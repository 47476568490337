import { useContext, useMemo, useState } from 'react';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { capitalize, formatDate } from '../../api/helpers';
import UserContext from '../../context/user/UserContext';
import { isUserRiskManager } from '../../context/user/utils';
import TextButton from '../common/Buttons/TextButton';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteRisk, reopenRisk, setCurrentRisk } from '../../redux/slices/risks';
import DeleteConfirmPopup from '../common/Popups/DeleteConfirmPopup';
import ConfirmationPopup from '../common/Popups/ConfirmationPopup';
import { Box, IconButton, Td, Tr, useToast, Tooltip } from '@chakra-ui/react';
import { getBusinessByName, getDepartmentByName } from '../../redux/utils';

const RisksTableRow = ({ risk, hiddenColumns }) => {
	const { user } = useContext(UserContext);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const [isReopenConfirmOpen, setIsReopenConfirmOpen] = useState(false);
	const [isReopenLoading, setIsReopenLoading] = useState(false);
	const toast = useToast();
	const location = useLocation();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isCurrentUserRiskManager = useMemo(() => {
		return isUserRiskManager(user);
	}, [user]);

	const isDeleteButtonVisible = useMemo(
		() => user.sub === risk.owner?.cognitoId || isCurrentUserRiskManager,
		[isCurrentUserRiskManager, user]
	);

	const handleEditClick = () => {
		dispatch(setCurrentRisk(risk));
		console.log(location.pathname);
		navigate(`/risk?riskId=${risk?._id}`, { state: { prevPath: location.pathname + location.search } });
	};

	const handleDeleteConfirm = async () => {
		setIsDeleteLoading(true);
		await dispatch(deleteRisk(risk._id));
		setIsDeleteConfirmOpen(false);
		setIsDeleteLoading(false);
		toast({ title: 'Risk moved to archive successfully', status: 'success' });
	};

	const handleAddResponseClick = () => {
		dispatch(setCurrentRisk(risk));
		navigate(`/risk/response?riskId=${risk._id}`);
	};

	const handleReopenClick = async () => {
		if (isReopenConfirmOpen) {
			setIsReopenLoading(true);
			await dispatch(reopenRisk(risk));
			setIsReopenLoading(false);
			setIsReopenConfirmOpen(false);
			toast({ title: 'Risk re-opened successfully', status: 'success' });
		} else {
			setIsReopenConfirmOpen(true);
		}
	};

	return (
		<Tr fontSize={14}>
			<DeleteConfirmPopup
				open={isDeleteConfirmOpen}
				isLoading={isDeleteLoading}
				onClose={() => setIsDeleteConfirmOpen(false)}
				onDelete={handleDeleteConfirm}
			/>
			<ConfirmationPopup
				isLoading={isReopenLoading}
				open={isReopenConfirmOpen}
				message={`Are you sure you want to re-open this risk? ${
					risk.response ? 'This would remove the current response to this risk.' : ''
				}`}
				onConfirm={handleReopenClick}
				onClose={() => setIsReopenConfirmOpen(false)}
			/>
			<Td>{risk.shortId}</Td>
			<Td whiteSpace='initial'>
				<Box width={100}>{risk.title}</Box>
			</Td>
			<Td>{capitalize(risk.status)} </Td>
			<Td>
				{risk.response?.response ? (
					capitalize(risk.response.response)
				) : (
					<TextButton onClick={handleAddResponseClick}>+ Add Response</TextButton>
				)}
			</Td>
			<Td>{getBusinessByName(risk.business)?.label || ''}</Td>
			<Td whiteSpace='initial'>
				<Box width={'80px'}>{getDepartmentByName(risk.department)?.label || ''}</Box>
			</Td>
			<Td whiteSpace='initial'>
				<Box width={'80px'}>{typeof risk.subDepartment === 'string' ? risk.subDepartment : ''}</Box>
			</Td>
			<Td>
				<Tooltip label={risk.owner ? risk.owner.email : ''}>
					<Box width={150}>
						{risk.owner
							? risk.owner?.name
							: risk.unregisteredOwnerName
							? `${risk.unregisteredOwnerName} (Unregistered)`
							: ''}
					</Box>
				</Tooltip>
			</Td>
			<Td textAlign='center'>{formatDate(risk?.creationTime)}</Td>

			<Td>
				<Box display='flex' alignItems='center' ju8stifyContent='center'>
					{risk.status === 'archived' ? (
						<TextButton onClick={handleReopenClick}>Re-open</TextButton>
					) : (
						<>
							<IconButton variant='ghost' onClick={(e) => handleEditClick(e)}>
								<RiEdit2Line />
							</IconButton>
							{isDeleteButtonVisible && (
								<IconButton variant='ghost' onClick={() => setIsDeleteConfirmOpen(true)}>
									<RiDeleteBinLine />
								</IconButton>
							)}
						</>
					)}
				</Box>
			</Td>
		</Tr>
	);
};

export default RisksTableRow;
