import moment from 'moment';

export const getHeaders = () => {
	const lsToken = localStorage.getItem(process.env.REACT_APP_TOKEN_TYPE);

	if (lsToken) {
		return {
			Authorization: lsToken,
		};
	} else return {};
};

export const validatePassword = (pwd) => {
	var regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,100}$/;
	var valid = regex.test(pwd);
	return valid;
};

export const validateEmail = (email) => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const generateMongoId = () => {
	const ObjectId = (m = Math, d = Date, h = 16, s = (s) => m.floor(s).toString(h)) =>
		s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

	return ObjectId;
};

/** Generates a short ID that's just 4 characters long. This short ID is displayed in the UI for the user to
 * easily recognize/identify a specific risk
 */
export const getRiskShortId = (riskId) => {
	return riskId.toString().slice(-4);
};

export const formatDate = (date, shortYear) => {
	return moment(date).format(`DD/MM/${shortYear ? 'YY' : 'YYYY'}`);
};

export const updateQueryParam = (name, value) => {
	const url = new URL(window.location.href);
	url.searchParams.set(name, value);
	window.history.replaceState(null, null, url);

	// popstate event is triggered in order for an 'onpopstate' window event listener to recognize it
	const event = new Event('popstate');
	window.dispatchEvent(event);
};

// Retrieve the value of a query parameter using its name
export const getQueryParam = (name) => {
	const url = new URL(window.location.href);
	return url.searchParams.get(name);
};
