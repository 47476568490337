import { Th, Thead, Tr } from '@chakra-ui/react';

const RisksTableHead = ({ sort, setSort }) => {
	return (
		<Thead>
			<Tr>
				<Th>#</Th>
				<Th width={100}>Title</Th>
				<Th>Status</Th>
				<Th>Response</Th>
				<Th>Business</Th>
				<Th width={'80px'}>Department</Th>
				<Th width={'80px'}>Sub-Department</Th>
				<Th>Owner</Th>
				<Th cursor='pointer' onClick={() => setSort?.({ _id: sort._id === -1 ? 1 : -1 })}>
					Creation Time {sort ? (sort._id === 1 ? '⇧' : '⇩') : ''}
				</Th>
				<Th textAlign='center'>Action</Th>
			</Tr>
		</Thead>
	);
};

export default RisksTableHead;
