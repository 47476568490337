import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateRisk } from './risks';

const initialState = {
	inputValues: {},
	errors: {},
	hasUnsavedChanges: false,
};

/**
 * if @riskId is provided, selected risk will be edited instead,
 * otherwise a new risk will be created
 */
export const submitRisk = createAsyncThunk('riskForm/saveRisk', async (riskId, thunk) => {
	let inputValues = { ...thunk.getState().riskForm.inputValues };

	if (!inputValues.status) {
		inputValues.status = 'identified';
	}

	if (!inputValues.actions) {
		inputValues.actions = [];
	}

	if (inputValues.inherentRisk) {
		Object.keys(inputValues.inherentRisk).forEach((key) => {
			if (typeof inputValues.residualRisk?.[key] !== 'number') {
				inputValues.residualRisk = {
					...(inputValues.residualRisk || {}),
					[key]: inputValues.inherentRisk[key],
				};
			}
		});
	}

	if (riskId) {
		const risks = thunk.getState().risks.risks;

		const selectedRisk = risks.find((risk) => risk._id === riskId);

		return await thunk.dispatch(updateRisk({ ...selectedRisk, ...inputValues }));
	} else {
		const result = await axios.post(`${process.env.REACT_APP_BASE_URL}/risks`, { ...inputValues });

		return result.data;
	}
});

export const riskFormSlice = createSlice({
	name: 'riskForm',
	initialState,
	reducers: {
		// Updates properties in state from incoming action payload
		updateRiskFormState: (state, action) => {
			let updatedErrors = { ...state.errors };
			Object.keys(action.payload).forEach((key) => {
				delete updatedErrors[key];
			});

			state.errors = updatedErrors;
			state.inputValues = { ...state.inputValues, ...action.payload };
			state.hasUnsavedChanges = true;
		},

		// Replaces current state with incoming action payload
		setRiskFormState: (state, action) => {
			state.inputValues = action.payload;
			// state.hasUnsavedChanges = true
		},

		resetRiskFormState: (state) => {
			state.inputValues = {
				theme: 'General',
			};
			state.errors = {};
			state.hasUnsavedChanges = false;
		},

		setError: (state, action) => {
			state.errors[action.payload.inputName] = action.payload.error;
		},

		resetErrors: (state) => {
			state.errors = {};
		},

		setHasUnsavedChanges: (state, action) => {
			state.hasUnsavedChanges = action.payload;
		},

		validateRiskInputs: (state) => {
			const requiredFields = [
				'business',
				'department',
				'title',
				'owner',
				'description',
				'theme',
				// 'status',
			];

			let updatedErrors = {};

			requiredFields.forEach((field) => {
				if (!state.inputValues[field]) {
					updatedErrors[field] = 'Required';
				}
			});

			if (state.inputValues.response) {
				const { residualRisk, inherentRisk, appetite, controls, causes, consequences } = current(
					state.inputValues
				);

				/** Before allowing a response to be added, a risk must first have all of the following fields filled first */
				if (
					!getOverallRiskRating(residualRisk) ||
					!getOverallRiskRating(inherentRisk) ||
					!getOverallRiskRating(appetite) ||
					!controls?.length ||
					!causes?.length ||
					!consequences?.length
				) {
					updatedErrors.response = {
						...(updatedErrors.response || {}),
						response:
							'Cannot add response without first adding Residual Risk, Inherent Risk, Appetite Risk, Controls, Causes and Consequences',
					};
				}

				/** Disallow submitting a response without filling the corresponding comments field */
				if (!state.inputValues.response.comments) {
					updatedErrors.response = {
						...(updatedErrors.response || {}),
						comments: 'Comments must be included with a response',
					};
				}
			}

			const unfinishedActions = state.inputValues.actions?.filter(
				(action) => action.status !== 'completed'
			);

			if (state.inputValues.status === 'archived' && unfinishedActions?.length) {
				updatedErrors.status = `Cannot archive risk with actions in progress (${unfinishedActions?.length})`;
			}

			state.errors = updatedErrors;

			// return Object.keys(updatedErrors).length > 0;
		},
	},

	extraReducers: (builder) => {
		builder.addCase(submitRisk.rejected, (state, action) => {
			console.log(action);
		});

		builder.addCase(submitRisk.fulfilled, (state) => {
			state.hasUnsavedChanges = false;
		});
	},
});

export const getRatingNumberColor = (rating) => {
	return (rating || 0) <= 3 ? '#26f05c' : rating <= 9 ? '#ffc517' : 'red';
};

export const getOverallRiskRating = (scoreValues) => {
	if (!scoreValues) {
		return 0;
	}
	const { likelihood, ...consequenceValues } = scoreValues;
	const value = Object.values(consequenceValues).length
		? Math.max.apply(Math, Object.values(consequenceValues)) * likelihood || 0
		: 0;

	return value;
};

export const {
	updateRiskFormState,
	setRiskFormState,
	resetRiskFormState,
	resetErrors,
	validateRiskInputs,
	setHasUnsavedChanges,
} = riskFormSlice.actions;
