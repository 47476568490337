import { useMemo } from 'react';
import {
	Box,
	Grid,
	GridItem,
	Slider,
	SliderFilledTrack,
	SliderMark,
	SliderThumb,
	SliderTrack,
	Text,
} from '@chakra-ui/react';
import { getOverallRiskRating } from '../../redux/slices/riskForm';

const RiskSliderInputs = (props) => {
	const { onInputChange, inputValues, isDisabled } = props;

	const overallRiskValue = useMemo(() => getOverallRiskRating(inputValues), [inputValues]);

	return (
		<div>
			<Text fontSize='2xl' fontWeight={500}>
				Likelihood
			</Text>

			<div>
				<Box mt={6} display='flex'>
					<Box width='31.7%'>
						<Input
							isDisabled={isDisabled}
							name='likelihood'
							value={inputValues.likelihood}
							onChange={onInputChange}
						/>
					</Box>
					<Box width='fit-content' ml='auto'>
						<OverallScoreBox value={overallRiskValue} />
					</Box>
				</Box>
				<Text mt={8} fontSize='2xl' fontWeight={500}>
					Consequence
				</Text>

				<Grid mt={6} templateColumns='repeat(3, 1fr)' gap={8}>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='finance'
							value={inputValues.finance}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='reputational'
							value={inputValues.reputational}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='operational'
							value={inputValues.operational}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='regulatory'
							value={inputValues.regulatory}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='legal'
							value={inputValues.legal}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='healthSafety'
							label='Health & Safety'
							value={inputValues.healthSafety}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='environment'
							value={inputValues.environment}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='security'
							value={inputValues.security}
							onChange={onInputChange}
						/>
					</GridItem>
					<GridItem>
						<Input
							isDisabled={isDisabled}
							name='aviationSafety'
							label='Aviation Safety'
							value={inputValues.aviationSafety}
							onChange={onInputChange}
						/>
					</GridItem>
				</Grid>
			</div>
		</div>
	);
};

const markStyles = {
	height: '8px',
	width: '2px',
	bgColor: 'primary',
	mt: 2.5,
	opacity: 0.5,
};

const Input = ({ value, label, name, onChange, isDisabled }) => {
	return (
		<Box
			borderColor='grey.100'
			borderWidth={1}
			overflow='hidden'
			display='flex'
			bgColor='white'
			height='70px'
			borderRadius={8}>
			<Box px={4} py={2} width='80%'>
				<Text fontWeight={500} color='primary' fontSize={14} textTransform='capitalize'>
					{label || name}
				</Text>
				<Box mt={0}>
					<Slider
						isDisabled={isDisabled}
						value={value || 0}
						min={-1}
						max={6}
						step={1}
						onChange={(value) => value >= 0 && value <= 5 && onChange(name, value)}>
						<SliderMark value={0} {...markStyles} />
						<SliderMark value={1} {...markStyles} />
						<SliderMark value={2} {...markStyles} />
						<SliderMark value={3} {...markStyles} />
						<SliderMark value={4} {...markStyles} />
						<SliderMark value={5} {...markStyles} />
						<SliderTrack height='3px' bg='blue.100'>
							<Box position='relative' right={10} />
							<SliderFilledTrack bg='primary' />
						</SliderTrack>
						<SliderThumb boxSize={3} />
					</Slider>
				</Box>
			</Box>
			<NumberBox value={value} />
		</Box>
	);
};

const OverallScoreBox = ({ value }) => {
	return (
		<Box
			borderColor='grey.100'
			borderWidth={1}
			overflow='hidden'
			display='flex'
			bgColor='white'
			height='70px'
			alignItems='center'
			borderRadius={8}>
			<Text color='primary' px={6}>
				Overall <b>Appetite Risk</b>
			</Text>
			<NumberBox value={value} />
		</Box>
	);
};

const NumberBox = ({ value }) => {
	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='center'
			height='100%'
			bgColor='primary'
			width='80px'
			ml='auto'
			fontSize={24}
			color='white'>
			{value || 0}
		</Box>
	);
};

export default RiskSliderInputs;
