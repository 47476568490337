import { useNavigate } from 'react-router-dom';
import { Box, Icon, Text, chakra } from '@chakra-ui/react';

const DashboardCard = ({ card }) => {
	const navigate = useNavigate();

	if (card.isHidden) {
		return <Box width={CARD_WIDTH} height={CARD_HEIGHT}></Box>;
	}

	return (
		<CardContainer onClick={() => navigate(card.url)}>
			<IconContainer>
				<Icon as={card.icon} color='white' fontSize='2xl' />
			</IconContainer>

			<Text color='primary' fontWeight={700} textTransform='uppercase'>
				{card.title}
			</Text>
		</CardContainer>
	);
};

const CARD_HEIGHT = '80px';
const CARD_WIDTH = '30%';

const CardContainer = chakra(Box, {
	baseStyle: {
		cursor: 'pointer',
		userSelect: 'none',
		borderRadius: 20,
		borderColor: 'primary',
		borderWidth: 1,
		height: CARD_HEIGHT,
		width: CARD_WIDTH,
		display: 'flex',
		alignItems: 'center',
		px: 4,
		py: 8,
	},
});

const IconContainer = chakra(Box, {
	baseStyle: {
		width: 50,
		height: 50,
		backgroundColor: 'primary',
		borderRadius: 16,
		display: 'flex',
		alignItems: 'center',
		mr: 4,
		justifyContent: 'center',
	},
});

export default DashboardCard;
